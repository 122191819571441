import React from "react";
function PodcastApps() {
  return (
    <ul className="distribution__links distribution__links_grid">
      <li className="distribution__links-el" data-v-bb7d009c="">
        <a
          href="https://podcasts.apple.com/no/podcast/jesus-alone-is-god/id1681425267"
          rel="noindex"
          target="blank"
          className="link distribution__link"
        >
          <div className="link__icon">
            <svg
              viewBox="0 0 300 300"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <defs>
                <linearGradient id="applePodcastsIconId1">
                  <stop offset="0" stopColor="#822cbe"></stop>{" "}
                  <stop offset="1" stopColor="#d772fb"></stop>
                </linearGradient>{" "}
                <linearGradient
                  id="applePodcastsIconId2"
                  x1="458.9"
                  x2="456.36"
                  y1="303.81"
                  xlinkHref="#applePodcastsIconId1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(-309.21)"
                ></linearGradient>
              </defs>{" "}
              <rect
                width="300"
                height="300"
                fill="url(#applePodcastsIconId2)"
                ry="66.74"
              ></rect>{" "}
              <path
                fill="#fff"
                d="M140.84 262.64c-8.95-3.2-10.87-7.55-14.54-33.04-4.27-29.63-5.2-47.97-2.73-53.78 3.28-7.7 12.18-12.07 24.63-12.12 12.35-.05 21.33 4.36 24.63 12.12 2.48 5.8 1.55 24.15-2.72 53.78-2.9 20.7-4.5 25.93-8.5 29.45-5.5 4.87-13.3 6.22-20.7 3.6zm-38.23-30.4c-30.9-15.2-50.7-40.92-57.9-75.14-1.8-8.83-2.1-29.9-.4-38 4.5-21.75 13.1-38.76 27.4-53.8 20.6-21.72 47.1-33.2 76.6-33.2 29.2 0 55.6 11.27 75.7 32.34 15.3 15.9 23.9 32.73 28.3 54.9 1.5 7.38 1.5 27.5.1 35.8-4.6 26.24-19.2 50.14-40.5 66.2-7.6 5.74-26.2 15.76-29.2 15.76-1.1 0-1.2-1.14-.7-5.75.9-7.4 1.8-8.94 6-10.7 6.7-2.8 18.1-10.92 25.1-17.94 12.1-12 21-27.7 25.1-44.2 2.6-10.3 2.3-33.2-.6-43.8-9.1-33.7-36.6-59.9-70.3-66.9-9.8-2-27.6-2-37.5 0-34.1 7-62.3 34.5-70.9 69.1-2.3 9.4-2.3 32.3 0 41.7 5.7 22.9 20.5 43.9 39.9 56.4 3.8 2.5 8.4 5.1 10.3 5.9 4.2 1.8 5.1 3.3 5.9 10.7.5 4.5.4 5.8-.7 5.8-.7 0-5.8-2.2-11.2-4.8zm.4-40.68c-10.4-8.3-19.6-23.02-23.4-37.46-2.3-8.72-2.3-25.3.1-34 6.3-23.48 23.6-41.68 47.6-50.23 8.2-2.9 26.4-3.55 36.5-1.32 34.8 7.75 59.5 42.6 54.7 77.17-1.9 13.93-6.7 25.37-15.2 36-4.2 5.37-14.4 14.38-16.2 14.38-.3 0-.6-3.4-.6-7.54V181l5.2-6.2c19.6-23.48 18.2-56.28-3.2-77.8-8.3-8.38-17.9-13.3-30.3-15.57-8-1.48-9.7-1.48-18.1-.1-12.75 2.08-22.63 7.02-31.4 15.7-21.5 21.3-22.9 54.27-3.3 77.77l5.16 6.2v7.6c0 4.2-.33 7.6-.74 7.6-.4 0-3.3-2-6.4-4.5zm34.7-40.83c-8.9-4.14-13.7-11.95-13.8-22.13 0-9.15 5.1-17.13 13.9-21.8 5.6-2.94 15.5-2.94 21.1.02 6.1 3.17 11.1 9.32 13 15.74 5.8 19.72-15.1 37-34 28.17z"
              ></path>{" "}
              <circle cx="149.89" cy="129.67" r="24.94" fill="#fff"></circle>{" "}
              <path
                fill="#fff"
                d="M152.28 164.27c1.73.23 5.17.68 8.35 1.7 3.2 1 6.1 2.57 8.36 4.18 2.2 1.6 3.8 3.26 4.8 5.28 1 2.02 1.5 4.42 1.8 8.35.2 3.94.2 9.4-.7 19.6-.9 10.17-2.7 25.08-4.1 34.63-1.4 9.6-2.4 13.8-3.7 16.9-1.3 3.2-3 5.3-4.9 6.8-1.9 1.5-4.1 2.4-6.2 2.9-2.1.5-4 .5-5.8.5-1.8 0-3.5 0-5.8-.5s-5.3-1.4-7.5-3.2c-2.3-1.8-3.8-4.4-5-7.8-1.2-3.4-2.1-7.5-3.3-15.7-1.2-8.2-2.8-20.4-3.8-29.7s-1.4-15.7-1.48-20c-.1-4.3.1-6.43.6-8.5.5-2 1.3-3.9 2.4-5.5 1.1-1.6 2.54-3 4-4.1 1.43-1.1 2.9-1.9 4.66-2.52 1.74-.7 3.83-1.3 6.4-1.8 2.6-.5 5.6-1 7.14-1.2 1.54-.23 1.54-.23 3.26 0z"
              ></path>
            </svg>
          </div>
          <div className="link__content" data-v-3fdcacd6="">
            <span data-v-3fdcacd6="">Listen on</span>
            <strong data-v-3fdcacd6="">Apple Podcasts</strong>
          </div>
        </a>
      </li>
      <li className="distribution__links-el" data-v-bb7d009c="">
        <a
          href="https://open.spotify.com/show/7G3HAP4sNs4PcO45vD1y0F"
          rel="noindex"
          target="blank"
          className="link distribution__link"
        >
          <div className="link__icon">
            <svg
              x="0"
              y="0"
              version="1.1"
              xmlSpace="preserve"
              viewBox="0 0 427.652 427.652"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#00d95f"
                d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z"
              ></path>
            </svg>
          </div>
          <div className="link__content">
            <span>Listen on</span>
            <strong>Spotify</strong>
          </div>
        </a>
      </li>
      <li className="distribution__links-el" data-v-bb7d009c="">
        <a
          href="https://podcasts.google.com/feed/aHR0cHM6Ly9tZWRpYS5yc3MuY29tL29ubHlqZXN1cy9mZWVkLnhtbA"
          rel="noindex"
          target="blank"
          className="link distribution__link"
          data-v-3fdcacd6=""
          data-v-bb7d009c=""
        >
          <div className="link__icon" data-v-3fdcacd6="">
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              data-v-bb7d009c=""
              data-v-3fdcacd6=""
            >
              <g fill="none">
                <path
                  fill="#0066d9"
                  d="M64 238.545v34.91c0 17.673-14.327 32-32 32s-32-14.327-32-32v-34.91c0-17.673 14.327-32 32-32s32 14.327 32 32z"
                ></path>{" "}
                <path
                  fill="#4285f4"
                  d="M448.013 239.455a32.6 32.6 0 01-.013-.91c0-17.673 14.327-32 32-32s32 14.327 32 32c0 .304-.004.608-.013.91H512v34.909h-.013c-.48 17.252-14.618 31.09-31.987 31.09s-31.506-13.838-31.987-31.09H448v-34.91h.013z"
                ></path>{" "}
                <path
                  fill="#ea4335"
                  d="M174.545 343.273v34.909c0 17.673-14.326 32-32 32s-32-14.327-32-32v-34.91c0-17.672 14.327-32 32-32s32 14.328 32 32zm0-209.455V248h-.008c-.386 17.337-14.562 31.273-31.992 31.273S110.94 265.337 110.554 248h-.009V133.818c0-17.673 14.327-32 32-32s32 14.327 32 32z"
                ></path>{" "}
                <path
                  fill="#34a853"
                  d="M337.455 168.727c0 17.673 14.326 32 32 32s32-14.327 32-32v-34.909c0-17.673-14.327-32-32-32s-32 14.327-32 32z"
                ></path>{" "}
                <path
                  fill="#fab908"
                  d="M224 66.91c0 17.672 14.327 32 32 32s32-14.328 32-32V32c0-17.673-14.327-32-32-32s-32 14.327-32 32zm0 378.18c0-17.672 14.327-32 32-32s32 14.328 32 32V480c0 17.673-14.327 32-32 32s-32-14.327-32-32z"
                ></path>{" "}
                <path
                  fill="#34a853"
                  d="M337.455 264.727c0-17.673 14.326-32 32-32s32 14.327 32 32v113.455c0 17.673-14.327 32-32 32s-32-14.327-32-32z"
                ></path>{" "}
                <path
                  fill="#fab908"
                  d="M288 162.91v186.18c0 17.674-14.327 32-32 32s-32-14.326-32-32V162.91c0-17.674 14.327-32 32-32s32 14.326 32 32z"
                ></path>
              </g>
            </svg>
          </div>
          <div className="link__content" data-v-3fdcacd6="">
            <span data-v-3fdcacd6="">Listen on</span>
            <strong data-v-3fdcacd6="">Google Podcasts</strong>
          </div>
        </a>
      </li>
    </ul>
  );
}

export default PodcastApps;
